.menu-prof {
  width: 100%;
}

.menu-prof button {
  display: block;
  background-color: rgba(var(--rbg-black), 0);
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  width: 100%;
  border: none;
  padding: 17px 20px;
  border-radius: 4px;
  text-align: left;
}

.menu-prof button:hover {
  background-color: rgba(var(--rbg-black), 0.03);
}

.menu-prof button.active {
  border-radius: 4px;
  background-color: rgba(var(--rbg-black), 0.05);
}

.menu-prof button:hover.active {
  background-color: rgba(var(--rbg-black), 0.09);
}

.menu-prof button .icon {
  font-family: "Icons";
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  color: var(--black);
  display: inline-flex;
  float: left;
  margin-right: 20px;
}

.name-prof {
  position: relative;
  top: 20px;
}


.title-prof {
  margin-top: 30px;
  margin-bottom: 30px;
}

.content-buttom {
  width: 100%;
  margin: 30px;
}

hr.prof-hr {
  border: 1px solid var(--black50);
  border-radius: 5px;
}

.content-full {
  background-color: var(--white);
  z-index: 400;
  width: 99vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.datos-title.lg {
  font-size: 18px;
  line-height: 23px;
}

.datos-title.md {
  font-size: 14px;
  line-height: 18px;
}

.m-btn-prof {
  margin-right: 30px;
}