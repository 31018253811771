.filter-container {
  margin-top: 100px;
}

.margin-filter {
  margin-bottom: 20px;
}

.btn-icon {
  font-family: Icons;
  font-style: normal;
}

.btn-content-filt {
  text-align: right;
  white-space: nowrap;
}

.text-title-tab {
  font-family: BasierCircle-regular;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  color: var(--black);
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 10px;
  display: table-cell;
  height: 34px;
}

.text-name {
  font-family: BasierCircle-Medium;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.text-dir {
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  top: 15px;
}

.text-dir i {
  font-family: Icons;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: var(--black) !important;
  margin-right: 8px;
  position: relative;
  display: inline-block;
  top: -7px;
}

.text-contract {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
  text-align: right;
}

.text-total-contr {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  position: relative;
  top: 15px;
}

.tab-container {
  margin-top: 50px;
}

.line-spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-pag {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.pag-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pag-content-btn {
  text-align: right;
}

.btn-pag-fotmar {
  padding-right: 0;
}

.content-center {
  text-align: center;
}

.text-title-info {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-info {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-email {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.icon-info-client {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.icon-info-client.sml {
  font-size: 30px;
  padding: 14px 0px;
}

.cont-icon-info {
  background: rgba(222, 222, 222, 0.5);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
}
.cont-icon-info.sml {
  width: 50px;
  height: 50px;
}
.content-info {
  min-height: 50vh;
  margin-top: 60px;
}

.content-info.smH {
  min-height: 25vh;
}

.btn-p-cl {
  margin-top: 0.3rem;
  padding: 12px 10px;
}

.contratos {
  left: -1px;
}

.row-cursor,
.row-cursor div,
.row-cursor label {
  cursor: pointer;
}

.m-accordion {
  margin-top: 60px;
  margin-bottom: 100px;
}

.btn-accordion {
  position: absolute;
  right: 0;
  z-index: 20;
  top: 12px;
}

.content-btns {
  display: flex;
  float: right;
}

.content-btns button {
  margin-left: 10px;
  margin-right: 10px;
}

.overflow-c {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.indicator {
  color: var(--error);
  opacity: 1;
}

.message{
  width: 200px !important;
  background: var(--default-3278e6) 0% 0% no-repeat padding-box !important;
  background: #3278E6 0% 0% no-repeat padding-box !important;
  color: var(--unnamed-color-ffffff) !important;
  font: normal normal medium 14px/18px Basier Circle !important;
  opacity: 1 !important;
  text-align: center !important;
  left: 30% !important;
}

.message.error {
  background: var(--error) 0% 0% no-repeat padding-box !important;
}

.btn-edc{
  position: relative;
  min-width: 153px;
  top: 5px;
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 2px solid #F2F8F3;
  padding: 14px 30px;
  color: black;
}

.btn-edc.active{
  background: var(--black) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: var(--white);
}

.lateralClient{
    margin: 0;
    background: white;
    min-width: 490px;
    max-width: 490px;
    min-height: 100%;
    position: relative;
    float: right;
    bottom: 0%;
}

.button-addProd{
  width: 352px;
  height: 54px;
  border: none;
  background-color: #F2F8F3;
  text-align: center;
  font-size: 18px;
}

.messagepp {
  margin-bottom: 80px;
}

.listsucup{
  margin-left: 10px;
}

.option-select{
  display: flex;
  cursor: pointer;
  padding: 5px 3px;
  border-radius: 10px 10px 10px 10px;
  background-color: rgba(var(--rbg-black), 0.05);
}

.fondocp{
  background-color: white;
}

.sizein{
  height: 146px;
  text-decoration: none;
}

.btn-regresar{
  position: absolute;
  top: 1.2%;
  left: -4px;
  background-color: transparent;
  border: none;
  color: var(--black);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: 23px;
  min-height: 40px;
  width: 40px;
  justify-content: left;
  opacity: 0.5;
}

.btn-regresar i{
  font-family: icons;
  font-weight: bold;
  font-style: normal;
}