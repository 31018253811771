.item-c-contr {
  text-align: center;
}

.item-c-contr>div {
  margin: auto;
}

.content-h .item-hidd {
  visibility: hidden;
}

.content-h .item-hidd.select {
  visibility: visible;
}

.content-h:hover .item-hidd {
  visibility: visible;
}

.text-indicator {
  color: var(--blueinf);
  background-color: rgba(var(--blueinf-rbg), 0.1);
  border-radius: 5px;
  padding: 10px 10px;
  text-align: center;
  position: relative;
  max-width: 120px;
}

.text-pos {
  align-items: center;
  background-color: var(--greenlight);
  border: 1;
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.text-money {
  position: relative;
  margin-right: 20px;
}

.text-money small {
  position: absolute;
  top: calc(-50% + 0.55em);
  font-size: 60%;
  height: 20px;
}

.btn-grab {
  cursor: -webkit-grab;
  cursor: grab !important;
}

.benef-tab:hover hr {
  opacity: 0.2;
  z-index: 0 !important;
}

.text-liq {
  font-style: normal;
  color: var(--success);
  font-size: 18px;
  letter-spacing: -0.54px;
}

.text-valid {
  font-style: normal;
  font-size: 18px;
  letter-spacing: -0.54px;
  padding: 6px 10px;
  color: var(--success);
  background-color: rgba(var(--success-rgb), 0.1);
  border-radius: 4px;
}

.text-valid .icon {
  font-style: normal;
  display: inline;
  font-family: Icons;
  top: -6px;
  margin-right: 4px;
  position: relative;
}


.text-no-valid {
  font-style: normal;
  font-size: 18px;
  letter-spacing: -0.54px;
  padding: 6px 10px;
  color: var(--information);
  background-color: var(--informationlight);
  border-radius: 4px;
  display: block;
}

.text-no-valid .icon {
  font-style: normal;
  display: inline;
  font-family: Icons;
  top: -6px;
  margin-right: 4px;
  position: relative;
}

.text-f-r {
  float: right;
}

.text-custom-title-tab {
  margin-right: 26px;
}

.content-alert {
  margin: 20% 0%;
  align-content: center;
  min-height: 50vh;
  max-height: 99vh;
}
.content-alert p {
  font-size: var(--font-size-lg);
  color: var(--bluedark);
}

@media (max-width: 600px) {
  .row-mov-h {
    min-height: 100px;
  }
  .mob-right {
    float: right;
    text-align: right;
    position: relative;
  }
}