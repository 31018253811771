.filter-container {
  margin-top: 100px;
}

.margin-filter {
  margin-bottom: 20px;
}

.btn-icon {
  font-family: Icons;
  font-style: normal;
}

.btn-content-filt {
  text-align: right;
}

.avatar {
  margin-right: -25px;
}

.subido {
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: left;
  background-color: rgb(233, 233, 233);
  font-family: BasierCircle-Regular;
  font-size: 16px;
  font-style: normal;
  color: #002D3C;
  background: rgb(238, 238, 238);
  border-radius: 4px;
  word-break: break-all;
}

.botonsubida {
  position: relative;
  border-color: transparent;
  background-color: white;
  font-family: BasierCircle-Regular;
  font-size: 16px;
  font-style: normal;
  color: #002D3C;
  padding-left: 0;
}

.subido i {
  position: relative;
  float: right;
  color: green;
  font-size: 19px;
}

.text-name {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.text-dir {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.text-contract {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
  text-align: right;
}

.text-total-contr {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  text-align: right;
}

.tab-container {
  margin-top: 50px;
}

.line-spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-pag {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.pag-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pag-content-btn {
  text-align: right;
}

.btn-pag-fotmar {
  padding-right: 0;
}

.content-center {
  text-align: center;
}

.text-title-info {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-info {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.absol {
  padding-left: 30%;
  padding-right: 30%;
}

.icon-info-client {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.cont-icon-info {
  background: rgba(222, 222, 222, 0.5);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
}

.add-container {
  height: 100vh;
  background-color: var(--white);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-xxl {
  margin-top: var(--margin-xxl);
}

.mt-xxs {
  margin-top: var(--margin-xxs);
}

.form-body {
  margin: var(--margin-xxxl);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.block {
  width: 360px;
}

.font-sm {
  font-size: var(--font-size-sm);
}

.b100 {
  background-color: var(--black);
}

.w100 {
  background-color: var(--white);
}

.disabled {
  opacity: 0.4;
}

.filter-container.sml {
  margin-top: 50px;
}

.margin-filter {
  margin-bottom: 20px;
}

.btn-icon {
  font-family: Icons;
  font-style: normal;
}

.btn-content-filt {
  text-align: right;
}

.avatar {
  margin-right: -25px;
}

.text-name {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.text-dir {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.text-contract {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
  text-align: right;
}

.text-total-contr {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  text-align: right;
}

.tab-container {
  margin-top: 50px;
}

.line-spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-pag {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.pag-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pag-content-btn {
  text-align: right;
}

.btn-pag-fotmar {
  padding-right: 0;
}

.content-center {
  text-align: center;
}

.text-title-info {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-info {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.absol {
  padding-left: 30%;
  padding-right: 30%;
}

.icon-info-client {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.cont-icon-info {
  background: rgba(222, 222, 222, 0.5);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
}

.add-container {
  height: 100vh;
  background-color: var(--white);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-xxl {
  margin-top: var(--margin-xxl);
}

.mt-xxs {
  margin-top: var(--margin-xxs);
}

.form-body {
  margin: var(--margin-xxxl);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.block {
  width: 360px;
}

.font-sm {
  font-size: var(--font-size-sm);
}

.b100 {
  background-color: var(--black);
}

.w100 {
  background-color: var(--white);
}

.disabled {
  opacity: 0.4;
}

.cont-btn-end {
  text-align: end;
}

.cont-btn-end button {
  margin-left: 10px;
  display: inline;
}

.link-file {
  text-decoration: none;
}

.content-btn-r > div {
  float: right;
}