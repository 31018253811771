.input {
  background-color: var(--white);
  border: 1px solid var(--black20);
  border-radius: var(--border-radius-input);
  color: var(--black);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  height: 54px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
  -webkit-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
  -moz-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
}

.input.textarea {
  height: auto;
}

.label {
  color: var(--black50);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
}

.input-content {
  margin-bottom: var(--margin-s);
}

.caption-error {
  color: var(--red);
}

.input.has-error {
  border: 1px solid var(--red);
  margin-bottom: 0.5rem;
}

.input::placeholder {
  font-size: var(--font-size-md);
  color: var(--black50);
}

.input:focus {
  background-color: var(--white);
  border: 1px solid var(--black50);
  color: var(--black);
  outline: none;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.3rem;
}

.input:disabled {
  background-color: var(--black05);
  box-shadow: 0px 0px 0.2px 0.5px rgba(var(--white-rbg), 0.55);
  -webkit-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--white-rbg), 0.55);
  -moz-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--white-rbg), 0.55);
}

.input-container>.input {
  background-color: var(--white);
  border: 1px solid var(--black20);
  border-radius: var(--border-radius-input);
  color: var(--black);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  height: 54px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0;
  margin-bottom: var(--margin-md);
}

.input-container>.input:focus {
  background-color: var(--white);
  border: 1px solid var(--black50);
  color: var(--black);
  outline: none;
}

.input-container>.input:disabled {
  background-color: var(--black05);
}

.input-container>.input:first-child {
  border-width: 1px 0 1px 1px;
  border-top-left-radius: var(--border-radius-input);
  border-bottom-left-radius: var(--border-radius-input);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container>.input:last-child {
  border-width: 1px 1px 1px 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--border-radius-input);
  border-bottom-right-radius: var(--border-radius-input);
}

.input-container>.input.has-error {
  border-color: var(--red);
  margin-bottom: 0.5rem;
}

.input-container>.input-icon {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--black20);
  border-radius: var(--border-radius-input);
  color: var(--black);
  cursor: pointer;
  display: flex;
  font-size: var(--icon-size);
  font-style: normal;
  height: 54px;
  justify-content: center;
  margin-bottom: var(--margin-md);
  position: relative;
  width: 2.5rem;
}

.input-container>.input:focus~.input-icon {
  background-color: var(--white);
  border: 1px solid var(--black50);
  color: var(--black);
  outline: none;
}

.input-container>.input-icon:first-child {
  border-top-left-radius: var(--border-radius-input);
  border-bottom-left-radius: var(--border-radius-input);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 1px 0 1px 1px;
}

.input-container>.input-icon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--border-radius-input);
  border-bottom-right-radius: var(--border-radius-input);
  border-width: 1px 1px 1px 0;
}

.input-container>.input.has-error~.input-icon {
  border-color: var(--red);
  margin-bottom: 0.5rem;
}

.input:focus~.input-icon {
  background-color: var(--color-background-light);
}

.input[type="radio"] {
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  height: 10px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: var(--margin-md);
}

.input-checkbox {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  margin: 0;
  font-family: BasierCircle-Regular;
  color: var(--black);
  width: var(--font-size-sm);
  height: var(--font-size-sm);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 0.15rem solid var(--black);
  border-radius: 50%;
  transform: translateY(0.075em);
  display: grid;
  place-content: center;
}

.input-checkbox::before {
  content: "";
  width: var(--font-size-sm);
  height: var(--font-size-sm);
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--black);
}

.input-checkbox:checked::before {
  transform: scale(1);
}

.form-label-radio {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1rem
}

.input-date {
  text-transform: uppercase;
}

.input-date::-webkit-calendar-picker-indicator {
  background: none;
}

/* placeholder text style */
.input-date::-webkit-datetime-edit-text,
.input-date::-webkit-datetime-edit-month-field,
.input-date::-webkit-datetime-edit-day-field,
.input-date::-webkit-datetime-edit-year-field {
  color: var(--black50);
}

/* regular text style */
.input-date.date-input--has-value::-webkit-datetime-edit-text,
.input-date.date-input--has-value::-webkit-datetime-edit-month-field,
.input-date.date-input--has-value::-webkit-datetime-edit-day-field,
.input-date.date-input--has-value::-webkit-datetime-edit-year-field {
  color: var(--black);
}

/* content-switcher */
.content-switcher {
  background-color: var(--white);
  border: 1px solid var(--black20);
  border-radius: var(--border-radius-input);
  color: var(--black);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  height: 54px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*Form close*/
.close-form {
  text-align: end;
}

.icon-close {
  font-size: var(--icon-size-md);
  cursor: pointer;
}

.icon-content {
  font-family: 'Icons';
  font-size: 21px;
  color: var(--black);
}

.icon-content.alert {
  font-size: 5em;
  color: var(--warning);
}

select {
  background-color: var(--white);
  border: 1px solid var(--black20);
  border-radius: var(--border-radius-input);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  height: 54px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: rgba(var(--rgb-black), 0.5);
  color: var(--black);
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
  -webkit-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
  -moz-box-shadow: 0px 0px 0.2px 0.5px rgba(var(--rbg-black), 0.20);
}

select:focus-visible {
  border: 1px solid var(--black20);
  outline: var(--black50) auto 16px;
}

option:checked {
  color: var(--black);
}

input[type="checkbox"] {
  border: 1px solid rgba(var(--rgb-black), 0.05);
  margin-right: 8px;
  height: 12px;
  width: 12px;
  opacity: 0.6;
  border-radius: 0;
}

input[type="checkbox"]:checked {
  border: 1px solid transparent;
  color: red !important;
}

input[type="checkbox"].md {
  border: 1px solid rgba(var(--rgb-black), 0.5);
  margin-right: 8px;
  height: 16px;
  width: 16px;
  opacity: 1;
  border-radius: 0;
}

input[type="checkbox"].md:checked {
  border: 1px solid transparent;
  background-color: black;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: var(--error) !important;
}

input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
  color: var(--black);
}

.input-file {
  color: transparent;
  min-width: 130px;
  z-index: 110;
  position: relative;
}

.input-file::-webkit-file-upload-button {
  visibility: hidden;
}

.input-file::file-selector-button {
  visibility: hidden;
  color: var(--bluedark);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Icons;
  content: '\E90D';
}

.input-file::before {
  color: var(--bluedark);
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-family: Icons;
  content: '\E90D';
  min-width: 130px;
  text-align: right;
  z-index: 110;
}

.input-file:hover::before {
  color: var(--bluedark);
}

.input-file:active {
  outline: 0;
}

.input-file:active::before {
  background: transparent;
}

.input-file-label {
  text-align: left;
  float: left;
  position: absolute;
  left: 0;
  top: 8px;
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--bluedark);
  z-index: 100;
  cursor: pointer;
}

.input-file-label i {
  color: var(--bluedark);
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-family: Icons;
  content: '\E90D';
  font-style: normal;
  top: -7px;
  right: -11px;
  position: relative;
}

/* ################## input-file-dark ########################*/
.input-file-dark {
  color: var(--white);
  min-width: 130px;
  z-index: 110;
  position: relative;
  visibility: hidden;
}

.input-file-dark::-webkit-file-upload-button {
  visibility: hidden;
}

.input-file-dark::file-selector-button {
  visibility: hidden;
  color: transparent;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.input-file-dark::before {
  visibility: hidden;
  color: transparent;
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  min-width: 130px;
  text-align: right;
  z-index: 110;
}

.input-file-dark:hover::before {
  visibility: hidden;
  color: transparent;
}

.input-file-dark:active {
  visibility: hidden;
  outline: 0;
}

.input-file-dark:active::before {
  visibility: hidden;
  background: transparent;
}

.input-file-dark-label {
  background: var(--bluedark);
  text-align: center;
  float: right;
  left: 0;
  top: 8px;
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--white);
  z-index: 100;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
}

/* ################## input-file-dark ########################*/

/* ################## input-file-light ########################*/
.input-file-light {
  color: var(--white);
  min-width: 130px;
  z-index: 110;
  position: relative;
  visibility: hidden;
  display: contents;
}

.input-file-light::-webkit-file-upload-button {
  visibility: hidden;
}

.input-file-light::file-selector-button {
  visibility: hidden;
  color: transparent;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.input-file-light::before {
  visibility: hidden;
  color: transparent;
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  min-width: 130px;
  text-align: right;
  z-index: 110;
}

.input-file-light:hover::before {
  visibility: hidden;
  color: transparent;
}

.input-file-light:active {
  visibility: hidden;
  outline: 0;
}

.input-file-light:active::before {
  visibility: hidden;
  background: transparent;
}

.input-file-light-label {
  background-color: var(--greenlight);
  border: 1;
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 10px 20px;
  cursor: pointer;
}

.input-file-light-label i {
  color: var(--bluedark);
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-family: Icons;
  content: '\E926';
  font-style: normal;
  top: 0px;
  right: -5px;
  cursor: pointer;
  position: relative;
}

/* ################## input-file-light ########################*/