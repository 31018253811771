.central {
  margin-top: 50px;
  margin-left: 25%;
  margin-right: 25%;
}

.firma {
  color: var(--success);
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: -0.54px;
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  top: 15px;
}

.firma i {
  top: -6px;
  position: relative;
}
