.options-container {
  position: relative;
}

.options-dropdown {
  background-color: var(--greenlight);
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  position: relative;
  width: 40px;
}

.options-container:hover .options-dropdown {
  background-color: rgba(var(--rbg-black), 0.1);
  opacity: 1;
}

.option-danger:hover {
  background-color: #FBF0F0;
  color: #C83532;
}

.option-success:hover {
  background-color: #F2F8F3;
  color: #4F9F52;
}