.menu {
  background-color: var(--white);
  width: 100%;
  min-height: 64px;
  position: fixed;
  top: 0;
  z-index: 110;
}

.menu-top-padding {
  padding-top: var(--margin-xxl);
}

.btn-menu {
  border: none;
  background-color: transparent;
  font-size: 28px;
  margin: 10px;
}

.img-ccontent-menu {
  text-align: center;
}

.img-menu {
  max-width: 300px;
  margin: 16px 5px;
}

.content-prof {
  position: relative;
  margin: 12px 0;
  float: right;
  display: flex;
}

.img-profile {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  border-radius: 50%;
}

.btn-profile {
  border: none;
  background-color: transparent;
  margin-right: 12px;
  padding: 0;
}

.btn-profile i {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #7F7F7F;
}

.username {
  font-family: 'BasierCircle-Medium';
  letter-spacing: -0.54px;
  color: var(--black);
  margin: 10px 16px 12px 20px;
  font-size: 18px;
}

.notifications {
  font-family: Icons;
  color: var(--black);
  margin: 0px 12px 0px 12px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
  border: none;
  background-color: transparent;
}

.notifications small {
  position: absolute;
  right: -5px;
  background-color: var(--information);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid var(--white);
  top: 5px;
}

.notifications label {
  font-size: 16px;
  position: absolute;
  font-family: 'BasierCircle-Regular';
  color: var(--white);
  width: 9px;
  height: 9px;
  top: -3px;
  right: 5px;
}

.prof-menu {
  position: absolute;
  right: 30px;
  background-color: var(--white);
  padding: 6px 6px;
  box-shadow: 0px 0px 30px 2px rgba(var(--rbg-black), 0.2);
}

.prof-menu button {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  width: -webkit-fill-available;
}

.prof-menu button.light {
  background-color: var(--greenlight);
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  padding: 0 var(--base-padding-button);
}

.menu-left {
  position: fixed;
  background-color: var(--white);
  min-height: 100vh;
  flex: 0 0 auto;
  max-width: 16.666666666%;
  min-width: 16.666666666%;
  z-index: 110;
}

.subt-menu-l {
  font-family: 'BasierCircle-Medium';
  letter-spacing: 0px;
  color: var(--black);
  position: relative;
  margin-top: 30px;
}

.menu-left .link-menu {
  position: relative;
  text-decoration: none;
  margin-top: 10px;
}

.menu-left .link-menu div {
  position: relative;
  background-color: rgba(var(--white-rbg), 0);
  border-radius: 4px;
  z-index: 10;
  padding: 15px 21px;
}

.menu-left .link-menu div.active {
  background-color: rgba(var(--light-gray-rbg), 1);
}

.menu-left .link-menu label {
  font-family: 'BasierCircle-Medium';
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  display: inline;
  font-size: 18px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.menu-left .link-menu .icon-menu {
  font-family: 'Icons';
  font-size: 21px;
  top: -5px;
  margin-right: 21px;
}

.menu-l-content {
  margin-top: 15px;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
  transform: translateX(-100%);
}

.fadeIn {
  opacity: 1;
  width: auto;
  height: 100vh;
  transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
  transform: translateX(0%);
}

.content-prof-notif {
  position: absolute;
  float: right;
  left: 0;
  min-width: 24vw;
  max-width: 98vw;
  top: 69px;
  box-shadow: 0px 0px 10px 2px rgba(var(--rbg-black), 0.2);
  border-radius: 4px;
  z-index: 200;
  background-color: var(--white);
  margin: 0 5px;
  padding-top: 15px;
}

.prof-notif {
  display: -webkit-box;
  margin: 12px 10px;
  cursor: pointer;
}

.prof-notif i {
  position: relative;
  font-family: Icons;
  font-style: normal;
  display: inline;
  background-color: rgba(var(--rbg-black), 0.2);
  width: 20px;
  height: 20px;
  font-size: 22px;
  padding: 10px 10px;
  border-radius: 50%;
  top: 3px;
  margin-right: 12px;
  margin-left: 10px;
}

.prof-notif u {
  position: relative;
  font-family: Icons;
  font-style: normal;
  display: inline;
  text-decoration: none;
  color: var(--information);
  position: absolute;
  right: 15px;
  margin-right: 12px;
  margin-left: 10px;
}

.prof-notif div {
  display: flow-root;
  width: 65%;
}

.prof-notif label, .prof-notif small {
  font-family: 'BasierCircle-Regular';
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  color: var(--black);
  line-height: 18px;
}

.prof-notif small {
  opacity: 0.5;
}

.notif-read {
  opacity: 0.5;
}

.prof-notif-more {
  text-align: center;
  margin: 20px 0px;
}

.prof-notif-more a {
  font-family: 'BasierCircle-Medium';
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--bluedark);
  text-decoration: none;
}

.content-no-notif {
  text-align: center;
  height: auto;
}

.content-no-notif:first-child div {
  margin-top: 50px;
  display: flex;
  text-align: center;
}

.content-no-notif i {
  position: relative;
  text-align: center;
  font-family: Icons;
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  margin: auto;
  background-color: rgba(var(--rbg-black), 0.2);
  width: 80px;
  height: 80px;
  font-size: 35px;
  padding: 14px 10px;
  border-radius: 50%;
}

.content-no-notif .title {
  font-family: 'BasierCircle-Medium';
  font-weight: normal;
  font-size: 18x;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0px;
  color: var(--black);
  word-wrap: break-word;
  margin: auto;
  margin-top: 20px;
}

.content-no-notif .message {
  font-family: 'BasierCircle-Medium';
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: var(--black);
  word-wrap: break-word;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 80%;
}

.no-notif {
  max-width: min-content;
}

.notif-bg-transp {
  background: transparent !important;
}

@media (max-width: 1200px) {
  .menu-left {
    max-width: 50%;
    min-width: 50%;
  }
}

@media (max-width: 768px) {
  .content-prof-notif {
    width: 98vw;
    max-width: 98vw;
  }
}

@media (max-width: 576px) {
  .menu-left {
    max-width: 100%;
    min-width: 100%;
  }

  .img-menu {
    max-width: 100%;
  }
}