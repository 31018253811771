.alert-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.alert-title {
  margin-top: var(--margin-l);
  margin-bottom: var(--margin-l);
  text-align: center;
}

.alert-subtitle {
  margin-bottom: var(--margin-l);
  text-align: center;
  width: 100%;
}

.alert-message {
  margin-bottom: var(--margin-xl);
  text-align: center;
}

.alert-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.alert-footer-space-between {
  justify-content: space-between;
}

.success-icon {
  width: 80px;
  height: auto;
  margin: auto;
}
