.filter-container {
  margin-top: 100px;
}

.margin-filter {
  margin-bottom: 20px;
}

.btn-icon {
  font-family: Icons;
  font-style: normal;
}

.btn-content-filt {
  text-align: right;
}

.estatus {
  color: rgb(84, 150, 221)
}

.text-name {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.text-dir {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.text-contract {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
  text-align: right;
}

.text-total-contr {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  text-align: right;
}

.tab-container {
  margin-top: 50px;
}

.line-spacing {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-pag {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.pag-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pag-content-btn {
  text-align: right;
}

.btn-pag-fotmar {
  padding-right: 0;
}

.content-center {
  text-align: center;
}

.text-title-info {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-info {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.icon-info-client {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.cont-icon-info {
  background: rgba(222, 222, 222, 0.5);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
}

.text-status-pr {
  color: var(--green);
  background-color: rgba(var(--green-rgb), 0.1);
  padding: 12px 8px;
  max-width: 92px;
  margin: auto;
  border-radius: 4px;
}

.text-status-pr.warn {
  color: var(--warning);
  background-color: var(--warninglight);
}
.text-status-pr.dang {
  color: var(--error);
  background-color: rgba(var(--error-rgb), 0.1);
}

.product-container {
  background-color: var(--black10);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-left: 0;
  margin-bottom: 0.5rem;
  margin-right: 0;
  padding: 1.5rem;
  width: 100%;
}

.product-title {
  color: var(--black);
  font-family: BasierCircle-Medium;
  font-size: var(--font-size-md);
}

.product-total {
  color: var(--black);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
}

.product-subtitle {
  color: var(--black50);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
}