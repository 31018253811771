.feature {
  background-color: rgba(var(--rbg-black), 0.08);
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 1rem;
  width: 100%;
}

.carousel-container {
  display: block;
  position: relative;
  margin-bottom: 1.5rem;
}

.carousel-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.carousel-pagination {
  align-items: center;
  background-color: #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  height: 40px;
  position: absolute;
  right: 24px;
  top: 12px;
  width: 40px;
  z-index: 10;
}

@media (min-width: 768px) {
  .carousel-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}