.login-container {
  height: 100vh;
  background-color: var(--white);
}

.img-fluid{
  max-width: 100%;
  height: 100vh;
  background-image: url('../../../../../public/assets/img/Login.webp');
}

.mt-m {
  margin-top: var(--margin-m);
}

.form-body {
  margin: var(--margin-xxxl);
  
}

.content-logo {
  text-align: center;
}

.logo-inicio {
  margin-bottom: var(--margin-m);
}

@media (max-width: 1400px) {
  .form-body {
    margin: var(--margin-m) !important;
  }
}

@media (max-width: 1088px) {
  .form-body {
    margin: var(--margin-s) !important;
  }
}