.tag {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 40px;
  width: fit-content;
  padding: 12px;
}

.tag-text {
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-sm);
}

.tag.success{
  background-color: var(--successlight);
  color: var(--success);
}

.tag.primary {
  background-color: var(--informationlight);
  color: var(--information);
}

.tag.expired {
  background-color: #0000000D;
  color: var(--color-text-primary)
}

.column-centered{
  display: flex;
  align-items: center;
}

.side-form-container {
  min-height: 100%;
}

.form-bottom-container {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}


/* Discounts form */