.content-centered {
  text-align: center;
}

.not-found-alert .title {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.not-found-alert .info {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.not-found-alert .icon {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.not-found-alert .icon-container {
  background: rgba(222, 222, 222, 0.5);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
}

