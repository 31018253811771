.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  max-width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.inputfile+label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.iborrainputfile {
  font-size: 16px;
  font-weight: normal;
  font-family: 'Lato';
}

/* style 2 */

.inputfile-2+label {
  color: #c39f77;
  border: 2px solid currentColor;
}

.inputfile-2:focus+label,
.inputfile-2.has-focus+label,
.inputfile-2+label:hover {
  color: #9f8465;
}

.input-img-file {
  width: 100%;
  min-height: 44vh;
  border: 2px solid var(--black20);
  opacity: 0;
  z-index: 10;
  position: absolute;
  cursor: pointer;
}

.span-img-file {
  width: 100%;
  min-height: 44vh;
  max-height: 50vh;
  border: 2px solid var(--black20);
  border-radius: 4px;
  z-index: 5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}
