.text-ciudad {
    font-family: BasierCircle-Regular;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.54px;
    color: var(--black);
    position: relative;
    top: 0px;
  }

  .text-ubisuc {
    font-family: BasierCircle-regular;
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    letter-spacing: -0.54px;
    color: var(--black);
    position: relative;
    display: inline-block;
    word-wrap: break-word;
    top: 0px;
  }
  
  .text-ubisuc i {
    font-family: Icons;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    color: var(--black) !important;
    margin-right: 8px;
    position: relative;
    display: inline-block;
    top: -7px;
  }