.i_checkbox {
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  border: 1px solid rgba(var(--rgb-black), 0.5) !important;
  margin-right: 8px !important;
  height: 18px !important;
  width: 18px !important;
  border-radius: 0.15em !important;
  outline: none;
  cursor: pointer;
}

.i_checkbox:disabled {
  cursor: initial;
}

.l_checkbox {
  border: 1px solid var(--bluedark) !important;
  border-radius: 2px !important;
  height: 20px !important;
  width: 20px !important;
  color: var(--white) !important;
}

.l_checkbox.small {
  transform: scale(0.75);
  -webkit-transform: scale(0.75);
  margin-right: 0.5rem;
}

.rounded {
  border-radius: 50% !important;
  overflow: hidden;
}

.checked {
  background-color: var(--white);
  position: relative;
  opacity: 1 !important;
}

.checked:disabled::before {
  color: var(--black) !important;
  opacity: 0.5 !important;
}

.checked::before {
  font-family: Icons;
  content: '\E913';
  font-size: 27px;
  color: var(--bluedark) !important;
  position: absolute;
  right: -3px;
  top: -2px;
}

.c_checkbox {
  display: inline-block;
}