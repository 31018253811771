.regresar-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: rgb(43, 55, 61);
    font-family: BasierCircle-SemiBold;
  }

  .regresar-btn::before {
    font-family: Icons;
    content: '\E90A';
    margin-right: 8px;
    color: rgb(43, 55, 61);
    
  }
  