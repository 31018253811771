.page-container {
  background-color: #F2F2F2;
  position: relative;
}

.block {
  width: 360px;
}

.block-buttons {
  position: relative;
  width: 10rem;
  height: 3rem;
  display: inline-flex;
}


.content-color-dark {
  position: relative;
  width: 10rem;
  height: 3rem;
  display: inline-flex;
  margin-right: var(--margin-s);
  color: var(--white);
}

.content-color-light {
  position: relative;
  width: 10rem;
  height: 3rem;
  display: inline-flex;
  margin-right: var(--margin-s);
  color: var(--black);
}

.b100 {
  background-color: var(--black);
}

.b50 {
  background-color: var(--black50);
}

.b20 {
  background-color: var(--black20);
}

.b05 {
  background-color: var(--black05);
}

.w100 {
  background-color: var(--white);
}

.w40 {
  background-color: var(--white40);
}

.w30 {
  background-color: var(--white30);
}

.w20 {
  background-color: var(--white20);
}

.bluedark {
  background-color: var(--bluedark);
}

.bluelight {
  background-color: var(--bluelight);
}

.green {
  background-color: var(--green);
}

.greenlight {
  background-color: var(--greenlight);
}

.blueinf {
  background-color: var(--blueinf);
}

.blueinflight {
  background-color: var(--blueinflight);
}

.yellow {
  background-color: var(--yellow);
}

.yellowlight {
  background-color: var(--yellowlight);
}

.red {
  background-color: var(--red);
}

.redlight {
  background-color: var(--redlight);
}