.type-notif {
  position: relative;
  font-family: Icons;
  font-style: normal;
  display: inline;
  background-color: rgba(var(--rbg-black), 0.05);
  width: 20px;
  height: 20px;
  font-size: 22px;
  padding: 10px 10px;
  border-radius: 50%;
  top: 3px;
  margin-right: 12px;
  margin-left: 10px;
}

.read-notif {
  font-family: Icons;
  font-style: normal;
  display: inline;
  text-decoration: none;
  color: var(--information);
  right: 15px;
}

.options-notif {
  font-family: Icons;
  font-style: normal;
  /*display: none;*/
  text-decoration: none;
  color: var(--black);
  font-size: 25px;
  float: right;
  cursor: pointer;
}

.options-btn {
  font-style: normal;
  /*display: none;*/
  text-decoration: none;
  font-size: 25px;
  float: right;
  cursor: pointer;
  background-color: var(--greenlight) !important;
  border: 1;
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  min-height: 30px;
  height: auto;
  justify-content: center;
  min-width: 30px;
  padding: 2px 9px;
  max-height: 30px;
  margin-top: -26px;
}

.option-tab {
  display: flex;
  cursor: pointer;
  padding: 5px 3px;
  border-radius: 10px 10px 10px 10px;
}

.option-tab.fdc {
  flex-direction: column;
}

.option-tab:hover {
  background-color: rgba(var(--rbg-black), 0.05);
  z-index: 1;
}

.option-tab:hover .options-notif,
.option-tab:hover .options-btn {
  display: inline;
}

.notif-line-spacing {
  margin: 17px 0;
}

.notif-line-spacing.light {
  color: var(--bs-accordion-border-color);
  background-color: var(--bs-accordion-border-color);
  opacity: 0.1;
}

@media (max-width: 576px) {
  .option-tab {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
  }
}