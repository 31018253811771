.profile-def {
  width: 32px;
  height: 32px;
  background-color: var(--greenlight);
  color: var(--bluedark);
  border-radius: 50%;
}

.profile-def label {
  color: var(--bluedark);
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  font-family: BasierCircle-Regular;
  letter-spacing: -0.84px;
  padding: 7px 0;
  text-align: center;
}

.profile-def img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile-def.md {
  width: 64px;
  height: 64px;
}

.profile-def.md label {
  font-size: 28px;
  line-height: 32px;
  padding: 16px 0;
  text-align: center;
}

.profile-def.md img {
  width: 64px;
  height: 64px;
}

.profile-def.lg {
  width: 96px;
  height: 96px;
}

.profile-def.lg label {
  font-size: 38px;
  line-height: 40px;
  padding: 28px 0;
  text-align: center;
}

.profile-def.lg img {
  width: 96px;
  height: 96px;
}

@media (max-width: 820px) {
  .profile-def.md, .profile-def.lg, .profile-def {
    float: right;
  }
}