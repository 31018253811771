/* ESTILOS GLOBALES DE LA APP */

/* FUENTES */
@font-face {
  font-family: 'BasierCircle-Bold';
  src: url('/public/assets/fonts/BasierCircle-Bold.otf');
}

@font-face {
  font-family: 'BasierCircle-BoldItalic';
  src: url('/public/assets/fonts/BasierCircle-BoldItalic.otf');
}

@font-face {
  font-family: 'BasierCircle-Medium';
  src: url('/public/assets/fonts/BasierCircle-Medium.otf');
}

@font-face {
  font-family: 'BasierCircle-MediumItalic';
  src: url('/public/assets/fonts/BasierCircle-MediumItalic.otf');
}

@font-face {
  font-family: 'BasierCircle-Regular';
  src: url('/public/assets/fonts/BasierCircle-Regular.otf');
}

@font-face {
  font-family: 'BasierCircle-RegularItalic';
  src: url('/public/assets/fonts/BasierCircle-RegularItalic.otf');
}

@font-face {
  font-family: 'BasierCircle-SemiBold';
  src: url('/public/assets/fonts/BasierCircle-SemiBold.otf');
}

@font-face {
  font-family: 'BasierCircle-SemiBoldItalic';
  src: url('/public/assets/fonts/BasierCircle-SemiBoldItalic.otf');
}

@font-face {
  font-family: Icons;
  src: url('/public/assets/fonts/WMP.otf') format("otf"),
    url('/public/assets/fonts/WMP.ttf') format("ttf"),
    url('/public/assets/fonts/WMP.woff') format("woff");
}

/* COLOR PALETTE */
:root,
*::before,
*::after {
  --black: #000000;
  --rbg-black: 0, 0, 0;
  --white: #FFFFFF;
  --bluedark: #002D3C;
  --bluelight: #7F959D;
  --green: #4F9F52;
  --green-rgb: 82, 159, 82;
  --greenlight: #F2F8F3;
  --blueinf: #3278E6;
  --blueinflight: #F0F5FE;
  --yellow: #EA9E1C;
  --yellowlight: #FEF8EF;
  --red: #C83532;
  --red-rgb: 200, 53, 50;
  --redlight: #FBF0F0;
  --white-rbg: 255, 255, 255;
  --light-gray-rbg: 242, 242, 242;
  --blueinf-rbg: 50, 120, 230;
  --red-rbg: 200, 53, 50;
  --yellow-rbg: 234, 158, 28;
  /* END COLOR PALETTE*/

  /* DESIGN SYSTEM COLORS */
  /* DARK */

  --black50: #00000050;
  --black20: #00000020;
  --black10: #00000010;
  --black05: #00000005;

  /* LIGHT */
  --white40: #FFFFFF40;
  --white30: #FFFFFF30;
  --white20: #FFFFFF20;

  /* PRIMARY */
  --color-text-primary: var(--black);
  --primarylight: var(--bluelight);

  /* FEEDBACK */
  /* SUCCESS */
  --success: var(--green);
  --success-rgb: var(--green-rgb);
  --successlight: var(--greenlight);

  /* INFORMATION */
  --information: var(--blueinf);
  --informationlight: var(--blueinflight);

  /* WARNING */
  --warning: var(--yellow);
  --warninglight: var(--yellowlight);

  /* ERROR */
  --error: var(--red);
  --error-rgb: var(--red-rgb);
  --errorlight: var(--redlight);
  /* END DESIGN SYSTEM COLORS*/

  /* SIZE */
  /* ICON SIZE */
  --icon-size-sm: 16px;
  --icon-size-md: 24px;
  --icon-size-lg: 32px;
  --icon-size-xl: 48px;

  /* DESIGN SYSTEM FONT SIZE */
  --font-size-xl: 3.375rem;
  --font-size-lg: 1.75rem;
  --font-size-md: 1.125rem;
  --font-size-sm: 0.875rem;

  /* DESIGN SYSTEM LINE HEIGHT */
  --line-height-xl: 3.875rem;
  --line-height-lg: 2rem;
  --line-height-md: 1.438rem;
  --line-height-sm: 1.125rem;

  /* DESIGN SYSTEM LETTER SPACING */
  --letter-spacing-xl: -0.121rem;
  --letter-spacing-lg: -0.053rem;
  --letter-spacing-md: -0.034rem;
  --letter-spacing-sm: 0rem;

  /* DESIGN SYSTEM BORDERS */
  --border-radius-button: 4px;
  /* 20px */
  --border-radius-input: 4px;
  /* 7px */
  --border-radius-card: 0.625rem;
  /* 10px */

  /* DESIGN SYSTEM ESPACIADOS */
  --margin-xxxs: 4px;
  --margin-xxs: 8px;
  --margin-xs: 12px;
  --margin-s: 16px;
  --margin-m: 24px;
  --margin-l: 32px;
  --margin-xl: 48px;
  --margin-xxl: 64px;
  --margin-xxxl: 96px;
  --base-padding-button: 1rem;
  --base-padding-button-left: 0rem 1rem 0rem 0rem;
}

*,
:before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  max-width: 100vw;
}

body> :nth-child(-n + 2) {
  max-width: 99.9vw;
  margin: auto;
}

h1 {
  color: var(--color-text-primary);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  letter-spacing: var(--letter-spacing-xl);
}

h2 {
  color: var(--color-text-primary);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  letter-spacing: var(--letter-spacing-lg);
}

h3,
h4,
h5,
h6,
label,
.text-bold {
  color: var(--color-text-primary);
  font-family: BasierCircle-Medium;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-md);
  display: block;
}

p, .text-regular {
  color: var(--color-text-primary);
  /* display: inline-block; */
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-md);
  margin: 0;
}

.text-secondary {
  color: var(--black50);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-md);
  margin: 0;
}

.small {
  color: var(--color-text-primary);
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  letter-spacing: var(--letter-spacing-sm);
  display: inline-block;
  position: relative;
}

.small-bold {
  color: var(--color-text-primary);
  font-family: BasierCircle-Medium;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  letter-spacing: var(--letter-spacing-sm);
  display: inline-block;
  position: relative;
}

/* END SIZE*/

.row-hover .hidden-col {
  display: block;
}

.row-hover:hover .hidden-col {
  display: block;
}

@media (hover: hover) {
  .row-hover .hidden-col {
    display: none;
  }
  
  .row-hover:hover .hidden-col {
    display: block;
  }
}

.content-error {
  position: relative;
  margin-top: 10%;
  background-color: rgba(var(--red-rgb), 0.5);
  color: var(--error);
  font-size: var(--font-size-sm);
  text-align: center;
}

.content-error h5 {
  color: var(--error);
  font-size: var(--font-size-lg);
  padding: 20px 10px;
}

button {
  cursor: pointer;
}

/* BUTTON PRIMARY */
.button {
  align-items: center;
  background-color: var(--bluedark);
  border: 0;
  border-radius: var(--border-radius-button);
  color: var(--white);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
}

/* BUTTON DISABLE*/
.button:disabled {
  cursor: default;
  opacity: 0.4;
}

/* BUTTON SECUNDARY */
.button-light {
  align-items: center;
  background-color: var(--white);
  border: 0;
  border-radius: var(--border-radius-button);
  color: var(--black50);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
}

.button-icon {
  font-family: Icons;
  font-size: var(--icon-size);
  margin-right: 0.15rem;
}

.button-tertiary {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--bluedark);
  text-decoration: none;
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
}

.button-tertiary:disabled {
  cursor: default;
  opacity: 0.4;
}

.button-tertiary:hover {
  background-color: var(--white);
  color: var(--bluedark);
  text-decoration: none;
}

.button-secondary {
  align-items: center;
  background-color: var(--greenlight);
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
  text-decoration: none;
}
.button-secondary.full {
  width: 100%;
}

/* BUTTON DISABLE*/
.button-secondary:disabled {
  cursor: default;
  opacity: 0.4;
}

.button-secondary:hover {
  filter: brightness(95%);
}

.button-secondary.active {
  background-color: var(--white);
  color: var(--bluedark);
  text-decoration: none;
}

.button-pag {
  align-items: center;
  background-color: var(--white);
  border: none;
  color: var(--black);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
  opacity: 0.5;
}

.button-pag.active {
  opacity: 1;
}

.button-pag i {
  font-family: Icons;
  font-weight: bold;
  font-style: normal;
}

.date {
  color: var(--color-text-primary);
  font-family: BasierCircle-Medium;
  font-size: var(--font-size-sm);

}

/* ICONS */
.icon-xl {
  font-size: var(--icon-size-xl);
}

.text-icon {
  display: inline-block;
  font-family: Icons;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: -7px;
  margin: 0;
}

.open-eye-line::before {
  font-family: Icons;
  content: '\E900';
}

.closed-eye-line::before {
  font-family: Icons;
  content: '\E901';
}

.user-list-line::before {
  font-family: Icons;
  content: '\E902';
}

.circle-line::before {
  font-family: Icons;
  content: '\E904';
}

.cross-line::before {
  font-family: Icons;
  content: '\E907';
}

.return-icon::before {
  font-family: Icons;
  content: '\E90A';
}

.loader-line::before {
  font-family: Icons;
  content: '\E91B';
}

.search-line::before {
  font-family: Icons;
  content: '\E919';
}

.dots-line::before {
  font-family: Icons;
  content: '\E923'
}

.pencil-line::before {
  font-family: Icons;
  content: '\E926'
}

.download-line::before {
  font-family: Icons;
  content: '\E92D'
}

.modal-full-size {
  width: 97vw;
  max-width: 97vw !important;
}

.absolute-content {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: var(--white);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 200;
}

.absolute-content.trasp {
  background-color: transparent;
  background-color: #00000010;
}

.close-page-icon {
  position: relative;
  color: var(--black);
  background-color: var(--white);
  width: 30px;
  height: 30px;
  float: right;
  font-size: 24px;
  font-family: Icons;
  padding-top: 30px;
  text-align: right;
  padding-right: 2vw;
  border: none;
}

.m-top-half {
  margin-top: var(--margin-xxxl);
}

.m-top-xxxs {
  margin-top: var(--margin-xxxs) !important;
}

.back-circle {
  background-color: rgba(var(--rbg-black), 0.1);
  border-radius: 50%;
}

.p-a {
  position: absolute;
}

.p-r {
  position: relative;
}

.pad-0 {
  padding: 0;
}

.drop-menu {
  position: absolute;
  top: 3.5rem;
  right: 0;
  min-width: 145px;
  background-color: var(--white);
  border-radius: 4px;
  padding: 6px 6px;
  box-shadow: 0px 0px 30px 2px rgba(var(--rbg-black), 0.2);
  z-index: 300;
  opacity: 1;
}

.drop-menu.lg {
  min-width: 180px;
}

.drop-menu.xl {
  min-width: 245px;
}

.drop-menu label,
.drop-menu button {
  background-color: rgba(var(--rbg-black), 0);
  padding: 8px;
  color: var(--black);
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-family: BasierCircle-Regular;
  position: relative;
  margin-bottom: 2px;
  min-height: 40px;
  opacity: 0.5;
  display: block;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  width: 100%;
}

.drop-menu .icon {
  font-family: Icons;
  font-size: 19px;
  font-style: normal;
  color: var(--black);
  opacity: 0.5 !important;
  position: relative;
  display: inline;
  background: transparent;
  top: -6px;
  margin-right: 5px;
}

.drop-menu label:hover,
.drop-menu button:hover {
  background-color: rgba(var(--rbg-black), 0.1);
  opacity: 1;
}

.accordion {
  --bs-accordion-color: var(--black);
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0px;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--black);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: none;
  --bs-accordion-btn-focus-border-color: var(--black05);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0rem rgba(var(--white-rbg), 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--black);
  --bs-accordion-active-bg: transparent;
}

.accordion-button::after {
  position: absolute;
  left: -10px;
  font-weight: 100;
  color: var(--black);
  font-family: Icons;
  content: '\E908';
  margin-top: -10px;
}

.accordion-button:not(.collapsed)::after {
  margin-top: 18px;
}

.accordion-button {
  margin-left: 20px;
  font-size: 28px;
  line-height: 32px;
  font-weight: normal;
  font-style: normal;
  font-family: BasierCircle-Regular;
}

.accordion-item {
  position: relative;
}

.accordion-body {
  border-bottom: 2px solid var(--black10);
}

.datos-title {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 0.5;
}

.datos-info {
  display: inline-block;
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  opacity: 1 !important;
  margin-bottom: 20px;
}

.sub-title-accordion {
  font-family: BasierCircle-Regular;
  font-size: 28px;
  line-height: 32px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.84px;
  color: var(--black);
  margin-bottom: 20px;
}

.accordion-button.collapsed {
  border-bottom: 2px solid var(--black10);
}

.react-switch-handle {
  width: 11px !important;
  height: 11px !important;
  top: 2px !important;
  left: -3px !important;
}

.m-a {
  margin: auto;
}

.wb-bw {
  word-break: break-word;
}
.wb-ba {
  word-break: break-all;
}

.no-margin div {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}

.f-r {
  float: right;
}

.content-on {
  opacity: 1;
  z-index: 100;
}

.radio-indicator {
  font-size: var(--font-size-lg);
  position: relative;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Icons;
  cursor: pointer;
}
.radio-indicator i {
  font-style: normal;
}

.content-center-imp {
  position: relative;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.text-dec-suby {
  text-decoration: line-through;
  opacity: 0.5;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.padd-top-img {
  padding-top: 18px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 820px) {
  .accordion-button {
    margin: auto;
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (max-width: 576px) {
  .btn-accordion {
    display: flex;
    top: 50px !important;
  }
}