.text-name {
  font-family: BasierCircle-Medium;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.text-nameP {
  font-family: BasierCircle-Medium;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  position: relative;
}

.text-suc {
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  top: 0px;
}

.text-suc i {
  font-family: Icons;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: var(--black) !important;
  margin-right: 8px;
  position: relative;
  display: inline-block;
  top: -7px;
}

.text-precio {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  position: relative;
  top: 0px;
}

.text-precio.left {
  left: 6%;
}
.text-precio.suby {
  text-decoration: line-through;
  opacity: 0.5;
}
.text-prev,
.text-stock {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  opacity: 0.5;
}

.text-type {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black) !important;
  opacity: 0.5;
  position: relative;
}

.icon-info-product {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
}

.precio {
  left: -1px;
}

.prodefault {
  width: 64px;
  height: 32px;
  background-color: var(--greenlight);
  color: var(--bluedark);
  border-radius: 5px;
}

.prodefault label {
  color: var(--bluedark);
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  font-family: BasierCircle-Regular;
  letter-spacing: -0.84px;
  padding: 7px 0;
  text-align: center;
}

.prodefault img {
  width: 64px;
  height: 32px;
  border-radius: 5px;
}

.prodefault.md {
  width: 90px;
  height: 64px;
}

.prodefault.md label {
  font-size: 28px;
  line-height: 32px;
  padding: 16px 0;
  text-align: center;
}

.prodefault.md img {
  width: 90px;
  height: 64px;
}

.prodefault.lg {
  width: 96px;
  height: 96px;
}

.prodefault.lg label {
  font-size: 38px;
  line-height: 40px;
  padding: 28px 0;
  text-align: center;
}

.prodefault.lg img {
  width: 96px;
  height: 96px;
}

.item-row {
  width: 100%;
  padding: 12px 18px;
  border: 1px solid var(--bluedark);
  border-radius: 4px;
  opacity: 0.6;
  position: relative;
  margin-top: 20px;
}

.item-row i {
  display: inline;
  top: 12px;
  right: 18px;
  position: absolute;
  cursor: pointer;
}

.img-p-c {
  width: 100%;
  margin-top: -5px;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.content-product {
  margin-top: 0%;
}

.img-product-select {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  flex-shrink: 0;
}

.btn-drop {
  position: absolute;
  background: var(--white);
  box-shadow: rgba(var(--rbg-black), 0.24) 0px 3px 8px;
  width: 40px;
  height: 40px;
  right: 15px;
  top: 15px;
  font-size: 25px;
  border-radius: 6px;
  z-index: 20;
  cursor: pointer;
}

.selectOp__control {
  box-shadow: 0 0 0 1px var(--white) !important;
  border-color: rgba(var(--rbg-black), 0.1) !important;
}

.selectOp__control:hover {
  border-color: rgba(var(--rbg-black), 0.1) !important;
  box-shadow: 0 0 0 1px var(--bluedark) !important;
}

.selectOp__multi-value__label {
  font-size: 18px !important;
}