.toggle {
  background-color: var(--black10);
  padding: 4px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.toggle-option {
  flex: 1;
  background-color: transparent;
  border: none;
  padding: 0px;
  border: none;
  border-radius: 10px;
}

.toggle-margin {
  border: 1px solid transparent;
  background-color: transparent;
  text-align: center;
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: -0.54px;
  color: var(--black);
  padding: 15px 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0;
}

.toggle-margin.active {
  border: 1px solid var(--white);
  background-color: var(--white);
}