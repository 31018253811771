.step-prosp {
  font-style: normal;
  display: inline;
}

.step-prosp-icon {
  position: relative;
  font-family: Icons;
  color: var(--black);
  font-style: normal;
  display: inline;
  top: -5px;
  margin-right: 10px;
}

.disable label, .disable i {
  opacity: 0.5;
}

.btn-gender {
  position: relative;
  top: 5px;
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--black);
  opacity: 0.2;
  padding: 14px 28px;
}

.btn-gender.active {
  background: var(--black) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: var(--white);
}

.genderM {
  border-radius: 4px 0px 0px 4px;
}

.genderF {
  border-radius: 0px 4px 4px 0px;
}

.btn-step {
  margin-top: var(--margin-xl);
}

.btn-step-prev {
  margin-right: 20px;
  color: var(--black);
  background-color: transparent;
  border: none;
}

.margin-form-title {
  margin-top: var(--margin-xxxl);
}

.small-info-input {
  display: block;
}

.cont-center {
  text-align: center;
  margin-top: 30px;
}

.cont-icon-resp {
  background: rgba(255, 255, 255, 0.15);
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
  margin-top: var(--margin-xxxl);
}

.cont-icon-resp.success {
  background: rgba(var(--success-rgb), 0.15);
}

.cont-icon-resp.error {
  background: rgba(var(--error-rgb), 0.15);
}

.cont-icon-resp.warning {
  background: rgba(var(--yellow-rbg), 0.15);
}

.cont-icon-resp.default {
  background: rgba(var(--light-gray-rbg), 1);
}

.icon-info-resp {
  font-family: Icons;
  font-size: 32px;
  color: var(--black);
  opacity: 1;
  padding: 29px 14px;
  text-align: center;
}

.icon-info-resp.lg {
  font-size: 48px;
}

.icon-info-resp.success {
  color: var(--success);
}

.icon-info-resp.error {
  color: var(--error);
}

.icon-info-resp.warning {
  color: var(--yellow);
}

.button-rep {
  margin: auto;
  text-decoration: none;
  padding: 10px 35px;
}

.button-rep:hover {
  text-decoration: none;
  color: var(--white);
  filter: brightness(150%);
}

.cont-resp-add {
  min-height: 60vh;
}

.text-title-accordion {
  font-size: 28px;
  line-height: 32px;
  font-weight: normal;
  font-style: normal;
  font-family: BasierCircle-Regular;
}

.pos-prospect {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.54px;
  margin-top: 6px;
  margin-bottom: 0;
  opacity: 1;
}

.btn-full-prd {
  width: 100%;
  padding: 12px;
}

@keyframes fadeinOp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeinOp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeinOp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.navBar-right {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 300;
}

.content-void {
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}

.content-mov-void {
  -webkit-animation: fadeinOp 0.5s ease-in;
  -moz-animation: fadeinOp 0.5s ease-in;
  animation: fadeinOp 0.5s ease-in;
  opacity: 1;
}

.content-navR {
  background-color: var(--white);
  overflow-x: auto;
  max-height: 100vh;
  opacity: 0;
  transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
  transform: translateX(100%);
}

.content-mov-in {
  opacity: 1;
  transition: 0.6s ease-in-out;
  -webkit-transition: 0.6s ease-in-out;
  transform: translateX(0%);
}

.spacing-nav {
  width: 100%;
  min-height: 6vh;
}

.content-nav-padd {
  padding: 0 10%;
}

.row-itemN {
  margin-top: 22px;
  /* margin-bottom: 22px; */
  padding: 15px 0;
  cursor: pointer;
}

.row-itemN:hover {
  background-color: rgba(var(--rbg-black), 0.1);
  border-radius: 4px;
}

.row-itemN.select {
  background-color: rgba(var(--rbg-black), 0.1);
  border-radius: 4px;
}

.row-itemN p {
  margin-bottom: 0px;
}

.row-itemN label {
  top: 5px;
  opacity: 1;
}

.cont-img-navR {
  margin-top: 7px;
  padding-left: 30px;
}

.nav-text-opacity {
  opacity: 0.4;
}

.navR-content-ov {
  overflow-x: auto;
  max-height: 65vh;
  margin-top: 5px;
}

.navR-content-btn {
  margin-top: 20px;
  width: 100%;
  /* position: absolute; */
  bottom: -10vh;
  margin-bottom: 20px;
  right: 0;
  left: 0;
}

.btn-iconPro {
  position: relative;
  top: -5px;
  font-family: Icons;
  font-style: normal;
}

.button-espera {
  align-items: center;
  background-color: var(--greenlight);
  border: 1;
  border: 1px solid var(--greenlight);
  border-radius: var(--border-radius-button);
  color: var(--bluedark);
  display: inline-flex;
  font-family: BasierCircle-Regular;
  font-size: var(--font-size-md);
  min-height: 40px;
  height: auto;
  justify-content: center;
  min-width: 40px;
  padding: 0 var(--base-padding-button);
}

.prospect-message-cont {
  background-color: rgba(var(--rbg-black), 0.08);
  border-radius: 4px;
  padding: 18px 5px;
  margin-left: 0;
  margin-right: 0;
}

.prospect-message-cont p {
  font-family: BasierCircle-Regular;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
}

.btn-full-c {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.cont-btn {
  min-height: 140px;
}

.cont-btn button {
  bottom: 1vh;
}

.navR-btn {
  margin-top: 20px;
  width: 100%;
  right: 0;
  left: 0;
}

.img-prd-cent>div {
  margin: auto;
  text-align: center;
}

.container-prd-number {
  bottom: 8%;
  right: 2.5%;
  display: flex;
  background-color: rgba(222, 222, 222);
  margin: auto;
}

.container-white {
  background-color: var(--white);
}

.container-h-full {
  min-height: 100vh;
}

.content-ovf {
  overflow-y: auto;
  max-height: 100vh;
}

.tab-seguimiento {
  margin: auto;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
  background-color: var(--black10);
  border-radius: 50%;
  padding: 6px 13px;
  width: 48px;
  height: 48px;
}

.select-g {
  color: var(--green);
  background-color: rgba(var(--green-rgb), 0.1);
}

.select-y {
  color: var(--warning);
  background-color: var(--warninglight);
}

.select-r {
  color: var(--error);
  background-color: rgba(var(--error-rgb), 0.1);
}

.interestSlt option[value="Alto"] {
  color: var(--green);
  background-color: rgba(var(--green-rgb), 0.1);
}

.interestSlt option[value="Medio"] {
  color: var(--warning);
  background-color: var(--warninglight);
}

.interestSlt option[value="Bajo"] {
  color: var(--error);
  background-color: rgba(var(--error-rgb), 0.1);
}

.m-text-l {
  margin-top: 12px;
}

.l-icon-btn {
  font-size: 24px;
  color: var(--black);
  margin-top: 2px;
  cursor: pointer;
}

.content-half {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.icon-i-select {
  font-family: Icons;
  font-style: normal;
  position: relative;
  top: -5px;
}

.cupon-info {
  background-color: var(--black20);
  width: 100%;
  padding: 20px 10px;
  position: relative;
  display: flex;
  opacity: 0.5;
  font-family: BasierCircle-Regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.54px;
  color: var(--black);
  border-radius: 4px;
}

.cupon-info i {
  position: absolute;
  font-family: Icons;
  font-style: normal;
  display: inline;
  right: 10px;
  cursor: pointer;
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (min-width: 1980px) {
  .container-prd-number {
    width: fit-content;
  }
}

@media (max-width: 576px) {
  .cont-img-navR {
    margin-top: 7px;
    padding-left: 13px;
  }
  .message-container {
    width: 90%;
  }
}