.m-xxxs {
  margin: var(--margin-xxxs);
}

.m-xxs {
  margin: var(--margin-xxs);
}

.m-xs {
  margin: var(--margin-xs);
}

.m-s {
  margin: var(--margin-s);
}

.m-m {
  margin: var(--margin-m);
}

.m-l {
  margin: var(--margin-l);
}

.m-xl {
  margin: var(--margin-xl);
}

.m-xxl {
  margin: var(--margin-xxl);
}

.m-xxxl {
  margin: var(--margin-xxxl);
}

.m-t-s {
  margin-top: var(--margin-s);
}

.m-t-l {
  margin-top: var(--margin-l);
}

.m-t-xl {
  margin-top: var(--margin-xl);
}

.m-t-xxl {
  margin-top: var(--margin-xxl);
}

.m-t-xxxl {
  margin-top: var(--margin-xxxl);
}

.m-b-l {
  margin-bottom: var(--margin-l);
}

.m-b-xl {
  margin-bottom: var(--margin-xl);
}

.m-b-xxl {
  margin-bottom: var(--margin-xxl);
}