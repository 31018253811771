.row-nav-page {
  background-color: var(--black10);
  padding: 4px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.option-nav-page {
  flex: 1;
  background-color: transparent;
  border: none;
  padding: 0px;
  border: none;
  border-radius: 10px;
}

.btn-nav {
  border: 0px solid var(--black05);
  background-color: transparent;
  text-align: center;
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: -0.54px;
  color: var(--black);
  padding: 15px 30px;
  width: 100%;
  min-width: 150px;
  border-radius: 10px;
  margin: 0;
  height: 100%;
}

.btn-nav.active {
  border: 1px solid var(--white);
  background-color: var(--white);
}

.nav-ma>div {
  margin: auto;
}

.nav-no-padd {
  padding: 0;
}

.nav-full-c {
  width: 100%;
}

.nav-full-r {
  width: 100%;
}