.Micon {
  font-family: Icons;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  position: relative;
  display: inline-block;
  margin: auto;
  text-align: center;
  margin-left: 10px;
}

.default {
  color: var(--information);
}

.error {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.defaultC {
  background-color: rgba(var(--blueinf-rbg), 0.08);
  padding: 16px 7px;
  border-radius: 4px;
}

.errorC {
  background-color: rgba(var(--red-rbg), 0.08);
  padding: 16px 7px;
  border-radius: 4px;
}

.warningC {
  background-color: rgba(var(--yellow-rbg), 0.08);
  padding: 16px 7px;
  border-radius: 4px;
}

.text_m {
  margin-top: 5px;
}