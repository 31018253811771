.add-container {
  height: 100vh;
  background-color: var(--white);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-xxl {
  margin-top: var(--margin-xxl);
}

.mt-xxs {
  margin-top: var(--margin-xxs);
}

.form-body {
  margin: var(--margin-xxxl);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.block {
  width: 360px;
}

.font-sm {
  font-size: var(--font-size-sm);
}

.b100 {
  background-color: var(--black);
}

.w100 {
  background-color: var(--white);
}

.disabled {
 opacity: 0.4;
}

.step-client {
  font-style: normal;
  display: inline;
}

.step-client-icon {
  position: relative;
  font-family: Icons;
  color: var(--black);
  font-style: normal;
  display: inline;
  top: -5px;
  margin-right: 10px;
}
