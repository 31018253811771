.back {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.5) !important;
}

.lateral {
  margin: 0;
  background: white;
  min-width: 470px;
  max-width: 490px;
  min-height: 100%;
  position: relative;
  float: right;
  bottom: 0%;
}

.btn-addp {
  min-width: 160px;
}

.list-prod-pros {
  margin-left: 5%;
  margin-right: 5%;
}

.row-nav-prodpros {
  background-color: var(--black10);
  padding: 4px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.option-nav-prodpros {
  flex: 1;
  background-color: transparent;
  border: none;
  padding: 0px;
  border: none;
  border-radius: 10px;
}

.margin-navprodpros {
  border: 1px solid transparent;
  background-color: transparent;
  text-align: center;
  font-family: BasierCircle-regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: -0.54px;
  color: var(--black);
  padding: 15px 30px;
  width: 100%;
  border-radius: 10px;
  margin: 0;
}

.margin-navprodpros.active {
  border: 1px solid var(--white);
  background-color: var(--white);
}

.margin_button {
  width: 432px;
  height: 54px;
  position: fixed;
  bottom: 1%;
  right: 2.5%;
  display: flex;
}

.container_number {
  position: relative;
  bottom: 8%;
  right: 0;
  left: 0;
  display: flex;
  background-color: rgba(222, 222, 222);
}

.input-number {
  width: 340px;
  height: 46px;
  text-align: center;
  font-size: 20px;
  padding-top: 7px;
}

.button-number {
  width: 46px;
  height: 46px;
  font-size: 23px;
  background-color: white;
  border-color: var(--black20);
}

@media (max-width: 820px) {
  .lateral {
    min-width: 80vw;
    max-width: 80vw;
  }

  .container_number {
    position: initial;
    left: 0%;
    right: 0%;
    display: flex;
    max-width: -webkit-fill-available;
  }

  .btn-nav-r {
    max-width: 100%;
  }

  .margin_button {
    width: 100%;
    position: initial;
    display: flex;
    margin: auto;
  }

  .input-number {
    width: 100%;
  }

  ._tab-list {
    min-height: 90vh;
    overflow-y: auto;
  }
}

@media (max-width: 576px) {
  .lateral {
    min-width: 99vw;
    max-width: 99vw;
  }
}